<script>
  // this view is only used for mobile workflow
  import CheckoutBreakdown from '@/components/checkout/CheckoutBreakdown'
  import CheckoutShipping from '@/views/CheckoutShipping'
  import CheckoutBilling from '@/views/CheckoutBilling'
  import OrderSummary from '@/components/checkout/summary/OrderSummary'

  export default {
    components: {
      CheckoutBreakdown,
      CheckoutShipping,
      CheckoutBilling,
      OrderSummary,
    },
    props: {
      storeType: {
        type: String,
        required: true,
        validator: (value) => ['marketplace', 'essentials'].includes(value),
      },
    },
    computed: {
      cart() {
        return `${this.storeType}Cart` // marketplaceCart, essentialsCart
      },
    },
    methods: {
      async placeOrderHandler() {
        await this.$store.dispatch(`${this.cart}/placeOrder`).then(() => {
          this.$navigate({ name: 'CheckoutConfirmation', params: { storeType: this.storeType } })
        })
      },
    },
  }
</script>

<template>
  <div>
    <div class="-mx-6 sm:-mx-9 -mt-4">
      <CheckoutBreakdown :cart="cart" @placeOrder="placeOrderHandler">
        <OrderSummary :type="storeType" view="checkout" />
      </CheckoutBreakdown>
    </div>
    <div class="-mx-2 pt-6">
      <CheckoutShipping :store-type="storeType" />
      <BaseDivider class="my-6" />
      <CheckoutBilling :store-type="storeType" />
    </div>
  </div>
</template>
